import React from "react"

import { CircleCheck } from "akar-icons"
import PropTypes from "prop-types"

import Button from "../../components/common/Button"
import Link from "../common/Link"
import "./order-success-main.scss"

const OrderSuccessMain = ({ orderId }) => {
  const orderNumber = atob(orderId).replace("Order:", "")

  return (
    <div className="order-success-main">
      <div className="order-success-main__container">
        <div className="order-success-main__icon-container">
          <CircleCheck size={72} color="#303546" />
        </div>
        <h3 className="order-success-main__heading">
          Thank You For Your Order!
        </h3>
        <p className="order-success-main__text">
          Your order number is {orderNumber}.
        </p>
        <p className="order-success-main__text">
          You will receive an order confirmation email with the order details.
        </p>
        <Button type="submit" color="primary" isBlock>
          <Link to="/">Continue Shopping</Link>
        </Button>
      </div>
    </div>
  )
}

OrderSuccessMain.propTypes = {
  orderId: PropTypes.string.isRequired,
}

export default OrderSuccessMain
