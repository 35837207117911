import React, { useEffect, useState } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import OrderSuccessMain from "../components/order/order-success-main.js"
import SEO from "../components/seo.js"

const OrderSuccessPage = ({ location }) => {
  const [isMounted, setIsMounted] = useState(false)
  const orderId = !!location.state?.orderId
  useEffect(() => {
    if (!orderId) {
      navigate("/")
      return
    }
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return <div></div>
  }

  return (
    <>
      <SEO title="Order Successful" />
      <OrderSuccessMain orderId={location.state?.orderId} />
    </>
  )
}

OrderSuccessPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default OrderSuccessPage
